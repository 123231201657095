import { ActivatedRouteSnapshot } from '@angular/router';
import { InvalidRoleError, UserInterface } from '@techniek-team/oauth';
import { PermissionService } from '@techniek-team/permissions';

/**
 * Roles that have access to this dashboard.
 */
const ALLOWED_ROLES: string[] = [
  'ROLE_SKOLEO_CORE_ADMIN',
  'ROLE_SKOLEO_CORE_READ_ONLY_ADMIN',
  'ROLE_SKOLEO_CORE_COORDINATOR',
  'ROLE_SKOLEO_CORE_SCHOOLCONTACT',
];

export function checkUserRoles(_permissionService: PermissionService) {
  return (currentUser: UserInterface | undefined, _route: ActivatedRouteSnapshot) => {
    if (!currentUser) {
      throw new InvalidRoleError();
    }

    const roles: string[] = currentUser.roles;
    const allowed: boolean = ALLOWED_ROLES.some((role) => roles.includes(role));
    if (!allowed) {
      throw new InvalidRoleError();
    }
  };
}
