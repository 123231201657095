import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { UserService } from '@techniek-team/oauth';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { catchError, of, switchMap, tap } from 'rxjs';
import { usersActions } from './users.actions';

@Injectable()
export class UsersEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);

  private readonly store = inject(Store);

  private readonly errorHandler = inject(SentryErrorHandler);

  private readonly toastService = inject(ToastService);

  private readonly userService = inject(UserService);

  public readonly createUsersFailureEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.loadUserFailure),
        tap((action) => {
          void Promise.all([
            this.errorHandler.captureError(action.error),
            this.toastService.error({
              message: 'Er is iets misgegaan bij het laden van uw gebruikersgegevens.',
              duration: 10000,
              buttons: [{ text: 'Sluiten', role: 'cancel' }],
            }),
          ]);
        }),
      ),
    { dispatch: false },
  );

  public readonly initUser = createEffect(() =>
    this.userService.currentUser().pipe(
      switchMap((user) => of(usersActions.loadUserSuccess({ user: user }))),
      catchError((error) => of(usersActions.loadUserFailure({ error: error }))),
    ),
  );

  public ngrxOnInitEffects(): Action {
    return usersActions.initUser();
  }
}
