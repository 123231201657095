import { Routes } from '@angular/router';
import { oauthAuthenticationGuard } from '@techniek-team/oauth';

export const ROUTES: Routes = [
  {
    path: 'login',
    loadComponent: () => import('@school-dashboard/login-page').then((c) => c.LoginPage),
    data: { preload: false },
  },
  {
    path: 'geen-toegang',
    loadComponent: () => import('@school-dashboard/forbidden-page').then((c) => c.ForbiddenPage),
    data: { preload: false },
  },
  {
    path: '',
    canActivate: [oauthAuthenticationGuard],
    loadComponent: () => import('./app/app.component').then((c) => c.AppComponent),
    data: { preload: false },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        canActivate: [oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/home').then((m) => m.ROUTES),
      },
      {
        path: 'leerlingen',
        canActivate: [oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/pupil-overview').then((m) => m.ROUTES),
      },
      {
        path: 'pupils',
        redirectTo: 'leerlingen',
      },
      {
        path: 'trajecten',
        canActivate: [oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/tracks-overview').then((m) => m.ROUTES),
      },
      {
        path: 'trajecten/:variant',
        loadChildren: () =>
          import('@school-dashboard/practise-track-progress').then((m) => m.ROUTES),
      },
      {
        path: 'tracks',
        redirectTo: 'trajecten',
      },
      {
        path: 'diensten',
        canActivate: [oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/services-overview').then((c) => c.ROUTES),
      },
      {
        path: 'begeleiders',
        canActivate: [oauthAuthenticationGuard],
        loadChildren: () => import('@school-dashboard/candidates-overview').then((c) => c.ROUTES),
      },
      {
        path: 'services',
        redirectTo: 'diensten',
      },
      {
        path: '**',
        redirectTo: '/home',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
  {
    path: 'track/:track',
    loadComponent: () => import('@school-dashboard/track-modal').then((c) => c.TrackModalComponent),
    outlet: 'modal',
    loadChildren: () => import('@school-dashboard/track-modal').then((c) => c.ROUTES),
  },
];
